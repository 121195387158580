export enum countryAlias {
  peru = '/pe/',
  ecuador = '/ec/',
  colombia = '/co/',
}

export enum localStorageVariable {
  country = 'country',
}

export enum countryAccess {
  peru = 'America/Lima',
  ecuador = 'America/Guayaquil',
  colombia = 'America/Bogota',
}
