import React, { FC } from 'react';
import { FloatingButtonProperties } from './models';
import './FloatingButton.scss';

const FloatingButton: FC<FloatingButtonProperties> = ({ fba }) => {
  const template = fba.map(
    ({
      properties: {
        image: { url, altText },
        link,
        mediaAriaLabel,
      },
    }) => {
      const { url: urlButton } = link[0];

      return (
        <a
          key={urlButton}
          href={urlButton}
          target="_blank"
          rel="noopener noreferrer"
          className="floating-button"
          aria-label={mediaAriaLabel || altText}
        >
          <img className="floating-button__img" src={url} alt={altText} />
        </a>
      );
    }
  );

  return <div className="floating-button__container">{template}</div>;
};

export default FloatingButton;
