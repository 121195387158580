import React, { FC, useContext } from 'react';
import { HeaderCountryComposition } from './models';
import './HeaderCountry.scss';
import { CountryContext } from '../../context/CountryContext/CountryContext';

const HeaderCountry: FC<HeaderCountryComposition> = (props) => {
  const { nodeCountry, setReloadUrl } = props;
  const { setState } = useContext<any>(CountryContext);
  const handleChangeCountry = (country) => {
    setState(country);
    const isBrowser = typeof window !== 'undefined';

    if (isBrowser) {
      localStorage.setItem('codeCountry', country);
      localStorage.setItem('country', country);
      window.location.href = '/';
    }
    setReloadUrl(false);
  };

  let countryStorage: string | null = '';
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    countryStorage = localStorage.getItem('codeCountry');
  }

  return (
    <ul className="countries">
      {nodeCountry?.map((node) => (
        <li
          key={node.properties.countryAlias}
          className={`country ${
            countryStorage === node.properties.countryAlias ? 'flag__active' : 'flag__not-active'
          }`}
        >
          <p className="country__name">
            {node.properties.countryAlias.replace(/\//g, '').toLocaleUpperCase()}
          </p>
          <div className="container__flag">
            <button
              className="country__flag"
              type="button"
              onClick={() => handleChangeCountry(node.properties.countryAlias)}
              title={node.properties.countryImage.altText}
            >
              <img
                src={node.properties.countryImage.fallbackUrl}
                alt={node.properties.countryImage.altText}
                className="image"
              />
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default HeaderCountry;
