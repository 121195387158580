const removeProperties = (productsResults) => {
  const products = productsResults.map((i) => {
    const { url } = i;

    return {
      url,
      ...i.properties,
    };
  });

  return products;
};

export default removeProperties;
