export const extractUrlFilter = (firstUrl, otherUrl) => {
  if (
    firstUrl === 'encuentra-tu-medida-ideal' ||
    firstUrl === 'encuentra_tu_producto' ||
    firstUrl === 'contactanos' ||
    firstUrl === 'influencers' ||
    firstUrl === 'articulos'
  ) {
    return `/${firstUrl}`;
  }

  return `/${firstUrl}/${otherUrl}`;
};

export default extractUrlFilter;
