import React, { FC } from 'react';
import { HeaderBandProps } from './models';
import './HeaderBand.scss';

const HeaderBand: FC<HeaderBandProps> = ({ cta, ctaCo, ctaEc, imageDesktop, imageMobile }) => {
  let urlByCountry = '';
  let targetByCountry = '';
  const isBrowser = typeof window !== 'undefined';
  let country: string | null = '';

  if (isBrowser) {
    country = localStorage.getItem('codeCountry');
  }

  switch (country) {
    case '/co/':
      urlByCountry = ctaCo[0].url;
      targetByCountry = ctaCo[0].target;
      break;
    case '/ec/':
      urlByCountry = ctaEc[0].url;
      targetByCountry = ctaEc[0].target;
      break;
    default:
      urlByCountry = cta[0].url;
      targetByCountry = cta[0].target;
      break;
  }

  return (
    <a
      href={urlByCountry}
      target={targetByCountry}
      rel="noopener noreferrer"
      className="headband__container"
    >
      <img className="headband--desktop" src={imageDesktop.url} alt={imageDesktop.altText} />
      <img className="headband--mobile" src={imageMobile.url} alt={imageMobile.altText} />
    </a>
  );
};

export default HeaderBand;
