import React, { FC, useContext } from 'react';
import Container from 'gatsby-theme-husky/src/layout/Container';
import BottomFooter from 'gatsby-theme-husky/src/components/Footer/BottomFooter';
import MediaFooter from './MediaFooter';
import { IFooterProps } from './model';
import 'gatsby-theme-husky/src/components/Footer/Footer.scss';
import 'gatsby-theme-husky/src/components/Footer/FooterRTL.scss';
import MiddleFooter from './MiddleFooter/MiddleFooter';
import { CountryContext } from '../../context/CountryContext/CountryContext';
import { ICountryContext } from '../../context/CountryContext/model';
import './Footer.scss';

const Footer: FC<IFooterProps> = ({
  bottomLinks,
  navigation,
  mediaLinks,
  hideMediaLinks,
  dataLogo,
  ldsNavigation,
}) => {
  const { socialMediaCountry, codeCountry, currentFooter: copyrightBlock } = useContext<
    ICountryContext
  >(CountryContext);

  const isBrowser = typeof window !== 'undefined';
  let country: string | null = '';

  if (isBrowser) {
    country = localStorage.getItem('codeCountry');
  }

  const ldsByCountry = ldsNavigation.filter(
    ({ properties }) => properties.country[0].properties.country[0] === country?.replace(/\//g, '')
  );

  return (
    <footer className="footer">
      <Container className="footer-container">
        <MediaFooter
          dataLogo={dataLogo}
          dataMediaLinks={mediaLinks}
          dataFooterHideMediaLinks={hideMediaLinks}
          codeCountry={codeCountry}
          socialMedia={socialMediaCountry}
        />
        <MiddleFooter
          dataNavigation={navigation}
          dataLinks={bottomLinks}
          ldsNavigation={ldsByCountry}
        />
        <BottomFooter dataCopyright={copyrightBlock} />
      </Container>
    </footer>
  );
};

export default Footer;
