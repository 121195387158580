import React, { FC, useCallback, useState, useContext } from 'react';
import NavItem from './NavItem/NavItem';
import { CountryContext } from '../../../context/CountryContext/CountryContext';
import { ICountryContext } from '../../../context/CountryContext/model';
import { NavProps } from './models';
import './HeaderNavigation.scss';

const HeaderNavigation: FC<NavProps> = ({
  allNodeNavigation: { nodes },
  handleActiveListPage,
  isReloadUrl,
  setReloadUrl,
}) => {
  const [activeMainCategory, setActiveMainCategory] = useState<string | null>(null);
  const { currentCountry } = useContext<ICountryContext>(CountryContext);

  const handleNavigationItemClick = useCallback(
    (id: string | null) => () => {
      setActiveMainCategory(id === activeMainCategory ? null : id);
    },
    [activeMainCategory]
  );

  return (
    <nav className="main-navigation">
      <ul className="main-navigation__list">
        {nodes
          .filter(
            (nav) =>
              nav.properties.country?.[0].properties.country[0] === currentCountry ||
              nav.properties.country?.[0].properties.country[0] === 'all'
          )
          .map((node) => (
            <NavItem
              key={node.properties?.label}
              {...{
                handleActiveListPage,
                handleNavigationItemClick,
                activeMainCategory,
                navItem: node,
                isReloadUrl,
                setReloadUrl,
              }}
            />
          ))}
      </ul>
    </nav>
  );
};

export default HeaderNavigation;
