import React, { FC, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';
import Seo from 'gatsby-theme-husky/src/common/Seo';
import PageSchema from 'gatsby-theme-husky/src/common/PageSchema';
import { AllSitePageContextProvider } from 'gatsby-theme-husky/src/context/allSitePage';
import { SiteSettingsContextProvider } from 'gatsby-theme-husky/src/context/siteSettings';
import { LayoutProps } from 'gatsby-theme-husky/src/layout/Layout/models';
import Header from 'gatsby-theme-husky/src/components/Header/Header';
import Footer from '../../components/Footer/Footer';
import 'gatsby-theme-husky/src/styles/main.scss';
import FloatingButton from '../../components/FloatingButton/FloatingButton';
import 'gatsby-theme-husky/src/layout/Layout/Layout.scss';
import CountryProvider from '../../context/CountryContext/CountryContext';

export const CommonSettingsContext = React.createContext({});

const Layout: FC<LayoutProps> = ({
  children,
  seo,
  headerTransparent,
  className,
  defaultCompositions,
  handleActiveListPage,
  disclaimerDesktopImage,
  disclaimerMobileImage,
  hideHeader,
  hideFooter,
}) => {
  const { siteSettings, commonSettings, footer, navigation, floatingButton } = defaultCompositions;
  const [initFlag, setInitFlag] = useState<boolean>(false);

  const { brandSettings, allSitePage } = useStaticQuery(
    graphql`
      query {
        brandSettings {
          brandName
          brandLogo {
            fallbackUrl
            svg {
              content
            }
          }
          brandLogoAlt
          brandLogoLinkAriaLabel
          brandName
          youtubeUrl
          facebookUrl
          mediaLinks {
            properties {
              mediaAriaLabel
              altLabel
              image {
                fallbackUrl
                svg {
                  content
                }
              }
              link {
                url
                name
              }
            }
          }
        }
        allSitePage {
          nodes {
            path
            context {
              id
              umbracoId
              url
              name
              lang
            }
          }
        }
      }
    `
  );
  let country: string | null = '';
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    country = localStorage.getItem('codeCountry');
  }

  let floatingButtonCountry = null;
  if (floatingButton && floatingButton.floatingButton.length > 1) {
    floatingButtonCountry = floatingButton.floatingButton.filter(
      (fbt) => `/${fbt.properties.country[0].properties.country[0]}/` === country
    );
  }

  return (
    <CommonSettingsContext.Provider value={commonSettings}>
      <SiteSettingsContextProvider value={siteSettings}>
        <AllSitePageContextProvider value={allSitePage}>
          <CountryProvider value={country}>
            <div
              className={classNames('layout layout-wrapper', className, {
                'layout--transparent': headerTransparent,
              })}
            >
              <Seo
                siteSettings={siteSettings}
                brandSettings={brandSettings}
                {...seo}
                lang={siteSettings.lang}
              />
              <PageSchema
                type="WebPage"
                siteSettings={siteSettings}
                brandSettings={brandSettings}
                name={seo.seoMetaTitle}
                data={{
                  metaTitle: seo.seoMetaTitle,
                  metaDescription: seo.seoMetaDescription,
                  metaKeywords: seo.seoMetaKeywords,
                }}
              />
              <a
                className="sr-only"
                href="#main"
                aria-label={navigation.skipContent[0].properties.skipContentTextAriaLabel}
              >
                {navigation.skipContent[0].properties.skipContentText}
              </a>
              {hideHeader ? null : (
                <Header
                  {...{
                    handleActiveListPage,
                    navigationTexts: navigation,
                    logo: brandSettings,
                    defaultCompositions,
                    languages: siteSettings.languages,
                    languageLetters: siteSettings.languageLetters,
                    baseLang: siteSettings.baseLang,
                    initFlag,
                    setInitFlag,
                  }}
                  searchTexts={{
                    ...commonSettings,
                    closeSearchAreaLabel: commonSettings.closeSeachAreaLabel,
                  }}
                  allNodeNavigation={{
                    nodes: navigation.navigation,
                  }}
                />
              )}
              {floatingButton ? (
                <FloatingButton fba={floatingButtonCountry || floatingButton.floatingButton} />
              ) : null}
              <main id="main" className="layout__main">
                {children}
              </main>
              {hideFooter ? null : (
                <Footer
                  {...{
                    mediaLinks: brandSettings.mediaLinks,
                    navigation: footer.navigation,
                    bottomLinks: footer.bottomLinks,
                    hideMediaLinks: footer.hideMediaLinks,
                    disclaimer: footer.disclaimer,
                    disclaimerDesktopImage,
                    disclaimerMobileImage,
                    ldsNavigation: footer.ldsNavigation,
                  }}
                  dataLogo={brandSettings}
                />
              )}
            </div>
          </CountryProvider>
        </AllSitePageContextProvider>
      </SiteSettingsContextProvider>
    </CommonSettingsContext.Provider>
  );
};

export default Layout;
