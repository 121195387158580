import React, { FC } from 'react';
import { Link } from 'gatsby';
import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';
import { useAllSitePageContext } from 'gatsby-theme-husky/src/context/allSitePage';
import { useSiteSettingsContext } from 'gatsby-theme-husky/src/context/siteSettings';
import { HeaderLogoProps } from './models';
import './HeaderLogo.scss';

const HeaderLogo: FC<HeaderLogoProps> = (props) => {
  const { brandLogo, brandLogoAlt, brandLogoLinkAriaLabel } = props.logo;
  const { getPageUrlByLang } = useAllSitePageContext();
  const { siteSettings } = useSiteSettingsContext();
  const langDependedHomeUrl = getPageUrlByLang('/', siteSettings.lang);

  return (
    <div className="main-header__logo">
      <Link
        to={langDependedHomeUrl}
        className="main-header__logo-link link"
        aria-label={brandLogoLinkAriaLabel}
      >
        <DangerouslySetInnerHtml html={brandLogo.svg.content} alt={brandLogoAlt} />
      </Link>
    </div>
  );
};

export default HeaderLogo;
