import React, { FC } from 'react';
import { Link } from 'gatsby';
import Row from 'gatsby-theme-husky/src/layout/Row';
import Col from 'gatsby-theme-husky/src/layout/Col';
import 'gatsby-theme-husky/src/components/Footer/MiddleFooter/MiddleFooter.scss';
import 'gatsby-theme-husky/src/components/Footer/MiddleFooter/MiddleFooterExtends.scss';
import { IMiddleFooterProps } from 'gatsby-theme-husky/src/components/Footer/MiddleFooter/model';

const MiddleFooter: FC<IMiddleFooterProps> = ({ dataNavigation, ldsNavigation }) => {
  return (
    <>
      <Row className="middle-footer-wrapper-top">
        {dataNavigation?.map((navigationLink, index) => {
          return (
            <Col
              className="wrapper-col-top"
              key={`${navigationLink.properties.url[0].name}${index}`}
            >
              <Link to={navigationLink.properties.url[0].url}>
                {navigationLink.properties.url[0].name}
              </Link>
            </Col>
          );
        })}
      </Row>
      <Row className="middle-footer-wrapper-bottom">
        {ldsNavigation && ldsNavigation.length > 0
          ? ldsNavigation.map(({ properties }, index) => (
              <Col className="wrapper-col-bottom" key={`${properties.cta[0].url}${index}`}>
                <Link to={properties.cta[0].url}>{properties.label}</Link>
              </Col>
            ))
          : null}
      </Row>
    </>
  );
};
export default MiddleFooter;
