interface IProductsResult {
  [k: string]: boolean | number | string | {};
}
const interSectionCollection = (activeAnswer, allProducts) => {
  const productsResults: IProductsResult[] = [];
  if (activeAnswer.length > 0) {
    activeAnswer.forEach(({ url }) => {
      const result = allProducts.nodes.find((i) => i.url === url);
      productsResults.push(result);
    });
  }

  return productsResults;
};

export default interSectionCollection;
