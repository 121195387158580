import React, { FC } from 'react';
import classNames from 'classnames';
import Button from 'gatsby-theme-husky/src/common/Button';
import extractUrlFilter from 'gatsby-theme-husky/src/utils/extractFilterUrl/extractUrlFilter';
import { IPropsNavItem } from '../models';

const NavItem: FC<IPropsNavItem> = (props) => {
  const {
    navItem: {
      properties: { navigationWithSubMenu, label, firstUrl, otherUrl },
    },
    activeMainCategory,
    handleNavigationItemClick,
    handleActiveListPage,
  } = props;

  return (
    <li
      className={classNames(`main-navigation-item`, {
        'main-navigation-item-with-sub': navigationWithSubMenu,
        active: activeMainCategory && activeMainCategory === label,
      })}
      onMouseEnter={navigationWithSubMenu ? handleNavigationItemClick(label) : undefined}
      onMouseLeave={handleNavigationItemClick(null)}
    >
      <a className="main-nav-link" href={extractUrlFilter(firstUrl, otherUrl)}>
        <Button
          classes={navigationWithSubMenu ? 'main-nav-link nav-link-with-sub' : 'main-nav-link'}
          ariaLabel={label}
          handler={() => (handleActiveListPage ? handleActiveListPage(0) : null)}
        >
          {label}
        </Button>
      </a>
      {navigationWithSubMenu ? (
        <ul className="secondary-nav">
          {navigationWithSubMenu?.map((item) => (
            <li className="secondary-nav__item" key={item.properties?.label}>
              <a
                onClick={handleNavigationItemClick(null)}
                className="main-nav-link secondary-nav__link"
                href={item.properties?.url[0].url ?? ''}
              >
                {item.properties?.label}
              </a>
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default NavItem;
