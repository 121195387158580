import React, { FC, useState, useCallback } from 'react';
import classnames from 'classnames';
import Button from 'common/Button';
import { SearchProps } from './model';
import './Search.scss';

const Search: FC<SearchProps> = (props) => {
  const {
    searchInputPlaceHolder,
    mobileSearchActive,
    showMobileSearch,
    searchAriaLabel,
    closeSearchAreaLabel,
    onSubmit,
    onOutsideClick,
  } = props;

  const [searchString, setSearchString] = useState('');

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchString(e.target.value);
    },
    [setSearchString]
  );

  const handleSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      if (e) {
        e.preventDefault();
      }
      const searchValue = searchString.trim();
      if (onSubmit && searchValue.length) {
        onSubmit(searchValue);
        setTimeout(() => {
          showMobileSearch();
        }, 500);
      }
    },
    [onSubmit, searchString]
  );

  return (
    <form className="search" onSubmit={handleSubmit} data-testid="search">
      {mobileSearchActive ? <div onClick={onOutsideClick} className="search__overlay" /> : null}
      <Button
        handler={showMobileSearch}
        className={classnames(
          'search__mobile-trigger-button',
          mobileSearchActive ? 'icon-close' : 'icon-search'
        )}
        ariaLabel={mobileSearchActive ? closeSearchAreaLabel : searchAriaLabel}
        type="button"
      />
      <div
        className={classnames('search__input-holder', { search__visible: mobileSearchActive })}
        onClick={onOutsideClick}
      >
        <div
          className="search__input-row"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <input
            value={searchString}
            onChange={handleInputChange}
            title="search"
            type="text"
            placeholder={searchInputPlaceHolder}
            className="search-input"
          />
          <Button
            className="search__input-submit-button icon-search"
            ariaLabel={searchAriaLabel}
            handler={handleSubmit}
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default Search;
