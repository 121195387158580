import React, { FC, useContext } from 'react';
import { Link } from 'gatsby';
import Row from 'layout/Row';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import { useAllSitePageContext } from 'context/allSitePage';
import { useSiteSettingsContext } from 'context/siteSettings';
import { ITopFooterProps } from './model';
import './TopFooter.scss';
import { CountryContext } from '../../../context/CountryContext/CountryContext';
import { ICountryContext } from '../../../context/CountryContext/model';

const MediaFooter: FC<ITopFooterProps> = ({ dataLogo }) => {
  const { getPageUrlByLang } = useAllSitePageContext();
  const { siteSettings } = useSiteSettingsContext();
  const langDependedHomeUrl = getPageUrlByLang('/', siteSettings.lang);
  const { socialMediaCountry } = useContext<ICountryContext>(CountryContext);

  return (
    <>
      <Link
        className="top-footer-logo__link"
        to={langDependedHomeUrl}
        aria-label={dataLogo?.brandLogoLinkAriaLabel}
      >
        <DangerouslySetInnerHtml
          html={dataLogo?.brandLogo.svg.content}
          alt={dataLogo?.brandLogoAlt}
        />
      </Link>
      <Row className="top-footer-row ">
        {socialMediaCountry?.map(({ properties }) => (
          <a
            href={decodeURIComponent(properties.value)}
            target="__blank"
            key={properties.key}
            aria-label={properties.value}
          >
            <img
              src={properties.img.fallbackUrl}
              alt={properties.img.fallbackUrl}
              className="top-footer-row__img"
            />
          </a>
        ))}
      </Row>
    </>
  );
};

export default MediaFooter;
