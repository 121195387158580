import React, { FC, useCallback, useEffect, useState } from 'react';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import classnames from 'classnames';
import HeaderLanguageSelector from 'gatsby-theme-husky/src/components/Header/HeaderLanguageSelector/HeaderLanguageSelector';
import Button from 'gatsby-theme-husky/src/common/Button';
import MobileHeaderNavigation from 'gatsby-theme-husky/src/components/Header/MobileHeaderNavigation/MobileHeaderNavigation';
import { HeaderProps } from 'gatsby-theme-husky/src/components/Header/models';
import HeaderLogo from './HeaderLogo/HeaderLogo';
import HeaderNavigation from './HeaderNavigation/HeaderNavigation';
import Search from './Search/Search';
import HeaderCountry from '../HeaderCountry/HeaderCountry';
import { countryAlias, localStorageVariable, countryAccess } from './types';
import HeaderBand from './HeaderBand/HeaderBand';
import './Header.scss';
import 'gatsby-theme-husky/src/components/Header/HeaderRTL.scss';

const Header: FC<HeaderProps> = ({
  navigationTexts,
  logo,
  allNodeNavigation,
  languages,
  languageLetters,
  baseLang,
  handleActiveListPage,
  searchTexts: { searchAriaLabel, searchPlaceholder, closeSearchAreaLabel },
  setCountry,
}) => {
  const [mobileStateVisibility, setMobileStateVisibility] = useState<boolean>(false);
  const [mobileSearchActive, setMobileSearchActive] = useState<boolean>(false);
  const [isLanguageSelectorActive, setIsLanguageSelectorActive] = useState<boolean>(false);
  const [isReloadUrl, setReloadUrl] = useState<boolean>(false);
  const showMobileMenu = useCallback(() => {
    setMobileStateVisibility(!mobileStateVisibility);
  }, [mobileStateVisibility, setMobileStateVisibility]);

  const showMobileSearch = useCallback(() => {
    setMobileSearchActive(!mobileSearchActive);
    setIsLanguageSelectorActive(false);
  }, [mobileSearchActive, setIsLanguageSelectorActive, isLanguageSelectorActive]);

  const handleSearchSubmit = useCallback((searchString: string) => {
    if (searchString.length) {
      const lang = languageLetters[0].toLocaleLowerCase();
      const langPrefix =
        lang === baseLang.replace('/', '') || languages.length === 1 ? '' : `/${lang}`;
      navigate(`${langPrefix}/search?q=${searchString}`);
    }
  }, []);

  const handleSearchOutsideClick = useCallback(() => {
    setMobileSearchActive(false);
    setIsLanguageSelectorActive(false);
  }, [setMobileSearchActive, setIsLanguageSelectorActive]);

  const handleLanguageSelectorToggle = useCallback(() => {
    setIsLanguageSelectorActive(!isLanguageSelectorActive);
    setMobileSearchActive(false);
  }, [setIsLanguageSelectorActive, isLanguageSelectorActive]);

  const headerWrapperClasses = classnames({
    'header-wrapper header-wrapper-mobile': mobileStateVisibility,
    'header-wrapper': !mobileStateVisibility,
    'header-wrapper__disabled-scroll': mobileSearchActive,
  });
  const headerMenuIcon = classnames({
    'main-nav-toggle-crose': mobileStateVisibility,
    'main-nav-toggle': !mobileStateVisibility || mobileSearchActive,
  });

  const handleCountry = () => {
    const isBrowser = typeof window !== 'undefined';
    let country: string | null = '';

    if (isBrowser) {
      country = localStorage.getItem('country');
    }

    setReloadUrl(true);
    country
      ? typeof window !== 'undefined'
        ? localStorage.setItem(localStorageVariable.country, country)
        : null
      : typeof window !== 'undefined'
      ? localStorage.setItem(localStorageVariable.country, countryAlias.peru)
      : null;
  };

  const handleCountryAccess = () => {
    if (Intl) {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      switch (userTimeZone) {
        case countryAccess.peru:
          typeof window !== 'undefined'
            ? localStorage.setItem(localStorageVariable.country, countryAlias.peru)
            : null;
          break;
        case countryAccess.colombia:
          typeof window !== 'undefined'
            ? localStorage.setItem(localStorageVariable.country, countryAlias.colombia)
            : null;
          break;
        case countryAccess.ecuador:
          typeof window !== 'undefined'
            ? localStorage.setItem(localStorageVariable.country, countryAlias.ecuador)
            : null;
          break;
        default:
          typeof window !== 'undefined'
            ? localStorage.setItem(localStorageVariable.country, countryAlias.peru)
            : null;
          break;
      }
    }
  };

  const handleExtractUrl = (): void => {
    const isBrowser = typeof window !== 'undefined';
    let country: string | null = '';

    if (isBrowser) {
      country = localStorage.getItem('country');
    }
    allNodeNavigation.nodes.forEach((node) => {
      if (node.properties.url[0] === undefined) return;
      const { url } = node.properties.url[0];
      node.properties.created = true;
      const firstUrl = url.split('/').filter((u) => u !== '')[0];
      node.properties.firstUrl = firstUrl;
      node.properties.otherUrl = url
        .split('/')
        .filter((u) => u !== '')
        .slice(1)
        .join('/');
    });

    allNodeNavigation.nodes.forEach((node) => {
      node.properties.navigationWithSubMenu?.forEach((sub) => {
        if (
          sub.properties?.url[0].name === '(Estimulantes)' &&
          (country === countryAlias.peru || country === countryAlias.ecuador)
        ) {
          sub.properties.created = false;
        } else if (
          sub.properties?.url[0].name === '(Mixed Packs)' &&
          (country === countryAlias.peru || country === countryAlias.ecuador)
        ) {
          sub.properties.created = false;
        } else if (
          sub.properties?.url[0].name === '(Estimulantes)' &&
          country === countryAlias.colombia
        ) {
          sub.properties.created = false;
        } else {
          sub.properties.created = true;
        }
        const subFirstUrl = sub.properties?.url[0].url.split('/').filter((u) => u !== '')[0];
        sub.properties.firstUrl = subFirstUrl;
        sub.properties.otherUrl = sub.properties?.url[0].url
          .split('/')
          .filter((u) => u !== '')
          .slice(1)
          .join('/');
      });
    });
  };

  const {
    headBand: { cta, ctaCo, ctaEc, imageDesktop, imageMobile },
  } = useStaticQuery(
    graphql`
      query {
        headBand {
          cta {
            url
            target
          }
          ctaCo {
            url
            target
          }
          ctaEc {
            url
            target
          }
          imageDesktop {
            url
            altText
          }
          imageMobile {
            url
            altText
          }
        }
      }
    `
  );

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined';

    if (isBrowser) {
      if (localStorage.getItem(localStorageVariable.country) === '') {
        handleCountryAccess();
      }
    }
    handleCountry();
    handleExtractUrl();
  }, [isReloadUrl]);

  return (
    <>
      <HeaderBand {...{ cta, ctaCo, ctaEc, imageDesktop, imageMobile }} />
      <header className={headerWrapperClasses}>
        <div className="header">
          <div className="header-bottom">
            <div className="wrapper">
              <HeaderLogo logo={logo} />
              {!mobileStateVisibility ? (
                <HeaderNavigation
                  {...{ handleActiveListPage, allNodeNavigation, isReloadUrl, setReloadUrl }}
                />
              ) : null}
              <Button
                className={headerMenuIcon}
                handler={mobileSearchActive ? showMobileSearch : showMobileMenu}
                type="button"
                ariaLabel={
                  !mobileStateVisibility
                    ? navigationTexts.openMobileNavigationAriaLabel
                    : navigationTexts.closeMobileMenuAriaLabel
                }
              />
            </div>
          </div>
          <div className="header-top">
            <Search
              {...{ showMobileSearch, searchAriaLabel, mobileSearchActive, closeSearchAreaLabel }}
              searchInputPlaceHolder={searchPlaceholder}
              onOutsideClick={handleSearchOutsideClick}
              onSubmit={handleSearchSubmit}
            />
            <HeaderCountry
              nodeCountry={navigationTexts.countryNavigation}
              setReloadUrl={setReloadUrl}
              {...{ setCountry }}
            />
            {languages.length > 1 ? (
              <HeaderLanguageSelector
                {...{ languageLetters, languages, handleSearchOutsideClick, baseLang }}
                isActive={isLanguageSelectorActive}
                onClick={handleLanguageSelectorToggle}
              />
            ) : null}
          </div>
        </div>
        <div />
        {mobileStateVisibility ? (
          <MobileHeaderNavigation
            allNodeNavigation={allNodeNavigation}
            navigationTexts={navigationTexts}
            toggleMobileMenu={showMobileMenu}
          />
        ) : null}
      </header>
    </>
  );
};
export default Header;
