import React, { FC, useState, useCallback, useContext } from 'react';
import { Link } from 'gatsby';
import Button from 'gatsby-theme-husky/src/common/Button';
import { NavProps } from './models';
import { CountryContext } from '../../../context/CountryContext/CountryContext';
import { ICountryContext } from '../../../context/CountryContext/model';
import './MobileHeaderNavigation.scss';

const MobileHeaderNavigation: FC<NavProps> = ({
  navigationTexts: { backToMainMenuAriaLabel, backToMainMenuText, openSubMenuAriaLabel },
  toggleMobileMenu,
}) => {
  const [activeSubMenu, setActiveSubMenu] = useState<any | null>(null);

  const { navigation, currentCountry } = useContext<ICountryContext>(CountryContext);

  const setActiveSubMenuHandler = useCallback(
    (menu: any) => {
      setActiveSubMenu(menu);
    },
    [activeSubMenu]
  );

  return (
    <div className="mobile-navigation">
      <ul className="mobile-navigation__ul">
        {!activeSubMenu &&
          navigation
            .filter(
              (nav) =>
                nav.properties.country?.[0].properties.country[0] === currentCountry ||
                nav.properties.country?.[0].properties.country[0] === 'all'
            )
            .map((link) => {
              const { label, url } = link.properties;
              const { properties } = link;

              return (
                <li
                  key={`${label}-mobile-navigation-with-submenu`}
                  className="mobile-navigation__li"
                >
                  {properties.navigationWithSubMenu ? (
                    <Button
                      className="arrow__icon"
                      ariaLabel={openSubMenuAriaLabel}
                      handler={() => setActiveSubMenuHandler(properties)}
                      type="button"
                      btnColorVariant="white-thin"
                    >
                      {label}
                    </Button>
                  ) : (
                    <Link to={url?.[0].url}>{label}</Link>
                  )}
                </li>
              );
            })}
      </ul>
      {activeSubMenu && activeSubMenu.navigationWithSubMenu && (
        <ul className="mobile-navigation__ul">
          <li className="mobile-navigation__li navigation-back">
            <Button
              className="arrow-back__icon"
              aria-label={backToMainMenuAriaLabel}
              handler={() => setActiveSubMenuHandler(null)}
              type="button"
              btnColorVariant="white-thin"
            >
              {backToMainMenuText}
            </Button>
          </li>
          {activeSubMenu.navigationWithSubMenu.map(({ properties: { label, url } }) => {
            const { url: urlMenu } = url[0];

            return (
              <Link
                key={`${label}-mobile-navigation-with-submenu-active`}
                to={urlMenu}
                activeClassName="active"
                onClick={() => toggleMobileMenu()}
              >
                <li className="mobile-navigation__li">{label}</li>
              </Link>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default MobileHeaderNavigation;
