import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import { useLocation } from '@reach/router';

import { SeoProps } from './models';

declare const window: any;

const Seo: FC<SeoProps> = ({
  siteSettings,
  brandSettings,
  lang,
  seoMetaKeywords,
  seoMetaTitle,
  seoMetaDescription,
  seoExternalHreflangs,
  seoAdditionalMeta,
  openGraphImage,
  isSearchPage = false,
  seoCanonicalUrl,
}) => {
  const { origin, href } = useLocation();

  const siteTitle = siteSettings.siteName || '';
  const siteAuthor = brandSettings.brandName;
  const siteLang = lang || siteSettings.lang;
  const siteDir = siteSettings.dir[0];
  const ogImage = openGraphImage ? origin + openGraphImage : brandSettings?.openGraphImage?.url;
  const robotsMeta = isSearchPage ? 'noindex, follow' : 'index, follow';

  const meta = [
    {
      name: 'robots',
      content: process.env.GATSBY_ACTIVE_ENV === 'production' ? robotsMeta : 'noindex, nofollow',
    },
    {
      name: 'description',
      content: seoMetaDescription,
    },
    {
      property: 'og:title',
      content: seoMetaTitle,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:url',
      content: href,
    },
    {
      property: 'og:description',
      content: seoMetaDescription,
    },
    {
      property: 'og:site_name',
      content: siteTitle,
    },
    {
      property: 'og:image',
      content: ogImage,
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: siteAuthor,
    },
    ...(seoMetaKeywords?.length
      ? [
          {
            name: 'keywords',
            content: seoMetaKeywords.join(', '),
          },
        ]
      : []),
  ];

  seoAdditionalMeta?.forEach(({ value, key }) => {
    return meta.push({ name: key, content: value });
  });

  const alternate = seoExternalHreflangs?.map((linkTag) => ({
    rel: 'alternate',
    ...linkTag,
  }));
  const canonical = seoCanonicalUrl
    ? [{ rel: 'canonical', key: seoCanonicalUrl, href: seoCanonicalUrl }]
    : [];
  // @ts-ignore
  const link = alternate ? alternate.concat(canonical) : canonical;

  useEffect(() => {
    window.OneTrust?.changeLanguage(siteLang);
  }, [siteLang]);

  return (
    <Helmet
      htmlAttributes={{ lang: siteLang, dir: siteDir }}
      {...{ title: seoMetaTitle || siteTitle, meta }}
      {...(link && { link })}
    >
      {process.env.GATSBY_ACCESSIBILITY_KEY ? (
        <script
          type="text/javascript"
          data-accesskey={process.env.GATSBY_ACCESSIBILITY_KEY}
          src={withPrefix(`AccessibilityScript.js`)}
        />
      ) : null}
    </Helmet>
  );
};

export default Seo;
