import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { ICountryContext, ICountryProvider, IGlobalBody, ISocialMediaCountry } from './model';

export const CountryContext = React.createContext<ICountryContext>({
  codeCountry: '',
  currentFooter: '',
});

const INIT_STATE = '/pe/';


const getCountryByIp = () => {
  return new Promise(( resolve, reject ) => {
    fetch('https://api.bigdatacloud.net/data/reverse-geocode-client').then(
      response => response.json()
    ).then(
      data => resolve(data?.countryCode)
    ).catch(reject)
  });
}

const CountryProvider = ({ children, value }: ICountryProvider) => {
  const [state, setState] = useState('');
  useEffect( () => {
    if (!value || value === 'undefined') {
      const isBrowser = typeof window !== 'undefined';
      if (isBrowser) {
        let countryCode;
        (async ()=> {
          countryCode = await getCountryByIp();
          switch(countryCode){
            case 'PE':
              localStorage.setItem('codeCountry', '/pe/');
              setState('/pe/');
              break;
            case 'CO':
              localStorage.setItem('codeCountry', '/co/');
              setState('/co/');
              break;
            case 'EC':
              localStorage.setItem('codeCountry', '/ec/');
              setState('/ec/');
              break;
            default:
              localStorage.setItem('codeCountry', INIT_STATE);
              setState(INIT_STATE);
              break;
          }
        })();
      }
      setState(INIT_STATE);
    } else {
      setState(value);
    }
  }, []);

  const {
    allUmbracoSitemap,
    allUmbracoLds,
    umbracoSitemap,
    allUmbracoProducts,
    allUmbracoProductListing,
    umbracoHome,
    allUmbracoQuiz,
    allProducts,
  } = useStaticQuery(
    graphql`
      query {
        allUmbracoSitemap {
          nodes {
            defaultCompositions {
              footer {
                copyrightBlockpe
                copyrightBlockco
                copyrightBlockec
                socialMedia {
                  properties {
                    img {
                      fallbackUrl
                      altText
                    }
                    key
                    value
                  }
                }
              }
            }
          }
        }
        umbracoSitemap {
          url
          pageName
        }
        umbracoHome {
          defaultCompositions {
            navigation {
              navigation {
                structure
                properties {
                  label
                  country {
                    properties {
                      country
                    }
                  }
                  url {
                    name
                    url
                    icon
                  }
                  navigationWithSubMenu {
                    structure
                    properties {
                      label
                      url {
                        name
                        url
                        icon
                      }
                    }
                  }
                  country {
                    structure
                    properties {
                      country
                    }
                  }
                }
              }
            }
          }
        }
        allUmbracoProducts {
          nodes {
            url
            name
            properties {
              showCountry {
                properties {
                  country
                }
              }
            }
          }
        }
        allUmbracoQuiz {
          nodes {
            quiz {
              country
              questions {
                id
                questionId
                additionalQuestionInfo
                backgroundImage {
                  url
                  fallbackUrl
                }
                options {
                  nextStep
                  disableByTag
                  dependQuestion
                  skipQuestion
                  dependResults {
                    url
                  }
                  dependTag {
                    name
                  }
                  products {
                    url
                  }
                  description
                  icon {
                    fallbackUrl
                    svg {
                      absolutePath
                      content
                      relativePath
                    }
                    url
                  }
                  id
                  imageAltText
                  localOptionIcon {
                    absolutePath
                    relativePath
                  }
                  singleSelection
                  tag
                  title
                }
                skipButtonAriaLabel
                skipButtonText
                subtitle
                title
              }
            }
          }
        }
        allUmbracoProductListing {
          nodes {
            name
            url
          }
        }
        allProducts: allUmbracoProducts {
          nodes {
            url
            properties {
              amountCondoms
              buttons {
                properties {
                  buttonText
                  buttonLink {
                    url
                  }
                }
              }
              image {
                properties {
                  imageAltLabel
                }
                localImage {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              quizRelatedProducts {
                url
                properties {
                  title
                  sku
                  amountCondoms
                  buttons {
                    properties {
                      buttonText
                      buttonLink {
                        url
                      }
                    }
                  }
                  image {
                    properties {
                      imageAltLabel
                    }
                    localImage {
                      childImageSharp {
                        fluid(maxWidth: 300) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                  quizTag {
                    id
                    name
                    title
                    icon {
                      fallbackUrl
                      svg {
                        content
                        absolutePath
                        relativePath
                      }
                      url
                      localQuizTagIcon {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                  sku
                }
              }
              sku
              title
            }
          }
        }
        allUmbracoLds {
          nodes {
            pageName
            codeCountry
            url
            seoMetaTitle
            seoMetaDescription
            seoMetaKeywords
            seoExternalHreflangs {
              hreflang: key
              href: value
            }
            defaultCompositions {
              ...DefaultCompositionsFragment
            }
            body
            versionId
            banner {
              properties {
                title
                variant
                localImage {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                imageAlt
                backgroundColour
              }
            }
          }
        }
      }
    `
  );
  const {
    copyrightBlockco,
    copyrightBlockec,
    copyrightBlockpe,
    socialMedia,
  } = allUmbracoSitemap.nodes[0].defaultCompositions.footer;

  const { nodes: allLds } = allUmbracoLds;
  const currentCountry = state?.replace(/[^a-zA-Z0-9 ]/g, '');
  const codeCountry = currentCountry?.charAt(0).toUpperCase() + currentCountry?.slice(1);
  const currentFooter = [copyrightBlockco, copyrightBlockec, copyrightBlockpe].find((i) =>
    i.includes(codeCountry)
  );
  const socialMediaCountry: ISocialMediaCountry[] = [];
  const globalLds: IGlobalBody[] = [];
  allLds.forEach((i) => {
    if (i.codeCountry === state) {
      globalLds.push(i);
    }
  });

  socialMedia.forEach((i: any) => {
    if (i.properties.key.includes(codeCountry)) socialMediaCountry.push(i);
  });
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    localStorage.setItem('codeCountry', state);
  }
  globalLds.push(umbracoSitemap);

  const { navigation } = umbracoHome.defaultCompositions.navigation;

  return (
    <CountryContext.Provider
      value={{
        socialMediaCountry,
        currentFooter,
        state,
        setState,
        codeCountry,
        globalLds,
        allLds,
        currentCountry,
        allUmbracoProducts,
        allUmbracoProductListing,
        navigation,
        allUmbracoQuiz: allUmbracoQuiz.nodes[0].quiz,
        allProducts,
      }}
    >
      {children}
    </CountryContext.Provider>
  );
};

export default CountryProvider;
